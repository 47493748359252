let elements = document.querySelectorAll('.js-ym-handle-add-product-to-cart')
let YA_ID = 27419105

for (let i = 0; i < elements.length; i++) {
    let element = elements[i]
    element.onclick = function (){
        if(window.ym){
            window.ym(YA_ID,'reachGoal','add-product-to-cart')
        }
    }
}

elements = document.querySelectorAll('.js-ym-handle-remove-product-from-cart')

for (let i = 0; i < elements.length; i++) {
    let element = elements[i]
    element.onclick = function (){
        if(window.ym){
            window.ym(YA_ID,'reachGoal','remove-product-from-cart')
        }
    }
}

elements = document.querySelectorAll('.js-ym-handle-to-checkout')

for (let i = 0; i < elements.length; i++) {
    let element = elements[i]
    element.onclick = function (){
        if(window.ym){
            window.ym(YA_ID,'reachGoal','to-checkout')
        }
    }
}

let element = document.getElementById('cart-coupon-form')

if(element){
    element.onsubmit = function (){
        if(window.ym){
            window.ym(YA_ID,'reachGoal','set-promo-code')
        }
    }
}
